<template>
    <div class="splash-screen">
        <div class="spinner-wrapper">
            <LoaderSpinner />
        </div>
    </div>
</template>

<style scoped>
.splash-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
}

.spinner-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
}
</style>
